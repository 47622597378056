import {useState} from "react";
import {useTranslation} from "react-i18next";

const Chips = () => {
  const {t} = useTranslation();
  const [activeFilters, setActiveFilters] = useState(['orange', 'green', 'blue']);

  const chips = [
    {name: t("chips.technologies.0"), color: "green", block: "technologies"},
    {name: t("chips.technologies.1"), color: "green", block: "technologies"},
    {name: t("chips.technologies.2"), color: "green", block: "technologies"},
    {name: t("chips.technologies.3"), color: "blue", block: "technologies"},
    {name: t("chips.technologies.4"), color: "green", block: "technologies"},
    {name: t("chips.technologies.5"), color: "green", block: "technologies"},
    {name: t("chips.technologies.6"), color: "green", block: "technologies"},
    {name: t("chips.technologies.7"), color: "blue", block: "technologies"},
    {name: t("chips.technologies.8"), color: "green", block: "technologies"},
    {name: t("chips.technologies.9"), color: "green", block: "technologies"},
    {name: t("chips.technologies.10"), color: "blue", block: "technologies"},
    {name: t("chips.technologies.11"), color: "blue", block: "technologies"},
    {name: t("chips.technologies.12"), color: "green", block: "technologies"},
    {name: t("chips.technologies.13"), color: "blue", block: "technologies"},
    {name: t("chips.technologies.14"), color: "green", block: "technologies"},
    {name: t("chips.technologies.15"), color: "green", block: "technologies"},
    {name: t("chips.tasks.0"), color: "orange", block: "tasks"},
    {name: t("chips.tasks.1"), color: "orange", block: "tasks"},
    {name: t("chips.tasks.2"), color: "blue", block: "tasks"},
    {name: t("chips.tasks.3"), color: "blue", block: "tasks"},
    {name: t("chips.tasks.4"), color: "blue", block: "tasks"},
    {name: t("chips.tasks.5"), color: "orange", block: "tasks"},
    {name: t("chips.tasks.6"), color: "blue", block: "tasks"},
    {name: t("chips.tasks.7"), color: "orange", block: "tasks"},
    {name: t("chips.tasks.8"), color: "blue", block: "tasks"}
  ];

  const handleFiltersChange = (event) => {
    const color = event.target.getAttribute("data-color")
    const classList = event.target.classList;
    if (classList.contains('active')) {
      setActiveFilters(activeFilters.filter(el => el !== color));
    } else {
      setActiveFilters([...activeFilters, color]);
    }
  };

  const handleAllFiltersChange = () => {
    if (activeFilters.length !== 3) {
      setActiveFilters(['orange', 'green', 'blue']);
    } else {
      setActiveFilters([]);
    }
  };

  const getFilterChips = (typeBlock) => {
    return chips?.filter(el => el.block === typeBlock && activeFilters.includes(el.color));
  };

  return (
    <div className="chips">
{/*
      <div className='chips-block'>
        <button
          className={`all-chips-button dark-blue${activeFilters.length === 3 ? ' active' : ''}`}
          onClick={handleAllFiltersChange}
        >
          {t("chips.button1")}
        </button>
        <button
          className={`chip-button orange${activeFilters.includes('orange') ? ' active' : ''}`}
          data-color='orange'
          onClick={handleFiltersChange}
        >
          {t("chips.button2")}
        </button>
        <button
          className={`chip-button green${activeFilters.includes('green') ? ' active' : ''}`}
          data-color='green'
          onClick={handleFiltersChange}
        >
          {t("chips.button3")}
        </button>
        <button
          className={`chip-button blue${activeFilters.includes('blue') ? ' active' : ''}`}
          data-color='blue'
          onClick={handleFiltersChange}
        >
          {t("chips.button4")}
        </button>
      </div>
*/}

      <div className='chips-technologies-block'>
        <p>{t("chips.infoBlock1")}</p>
        <div className='chips-block'>
          {getFilterChips('technologies').length > 0 ? (
            getFilterChips('technologies').map((el, index) => (
              <div className={el.color} key={index}>{el.name}</div>
            ))
          ) : (
            <span>{t("chips.emptyInfoBlock1")}</span>
          )}
        </div>
      </div>

      <div className='chips-technologies-block'>
        <p>{t("chips.infoBlock2")}</p>
        <div className='chips-block'>
          {getFilterChips('tasks').length > 0 ? (
            getFilterChips('tasks').map((el, index) => (
              <div className={el.color} key={index}>{el.name}</div>
            ))
          ) : (
            <span>{t("chips.emptyInfoBlock2")}</span>
          )}
        </div>
      </div>
    </div>
  )
}

export {Chips}
