import {useContext, useEffect, useCallback, useState} from "react";
import {Nav} from 'react-bootstrap';
import {useTranslation} from "react-i18next";

import {Context} from "../common/scripts/context";
import {CustomLink} from "../components/CustomLink";
import {ContactForm} from "../components/ContactForm";
import {Projects} from "../components/Projects";

import codeBracesImage from '../common/images/code-braces.svg';
import schoolOutlineImage from '../common/images/school-outline.svg';
import chartLineImage from '../common/images/chart-line.svg';
import dashboardProjectImage from '../common/images/dashboard-projest.png';
import cagesProjectImage from '../common/images/cages-projest.png';
import platformProjectImage from '../common/images/platform-project.png';
// import {CookiesModal} from "../components/CookiesModal";

const HomePage = () => {
  const {t, i18n} = useTranslation();
  const defaultMessage = t('homePage', {returnObjects: true});

  const [activeTab, setActiveTab] = useState('tab1');
  const {setFooterTheme} = useContext(Context);
  const [messages, setMessages] = useState(defaultMessage);
  // const [isShow, setIsShow] = useState(!localStorage.getItem("cookies"));

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    setFooterTheme('dark');
  }, [setFooterTheme]);

  useEffect(() => {
    setMessages(defaultMessage);
  }, [i18n.language, t]);

  const servicesParams = [
    {imgHref: codeBracesImage, link: "/services/servicenow"},
    {imgHref: chartLineImage, link: "/services/data-analytics"},
    {imgHref: schoolOutlineImage, link: "/services/servicenow-training"},
  ];

  const projectsPhoto = [
    {imgHref: platformProjectImage},
    {imgHref: cagesProjectImage},
    {imgHref: dashboardProjectImage},
  ]

  const getProjectsList = useCallback(() => {
      return messages.projects.options.map((el, index) => ({
        blockSize: 'large',
        imgHref: projectsPhoto[index].imgHref,
        title: el.title,
        subtitle: el.subtitle,
        description: el.description,
        link: `/services/servicenow/projects/${el.linkTitle}`
      }));
    }, [],
  );

  return (
    <div className='home-page page'>
      <section className='home-page__main'>
        <div className="section-wrapper">
          <div className='home-page__main-block'>
          <h1>{t('homePage.main.title')}</h1>
          <p className='home-page__main-description'>
            {t('homePage.main.description')}
          </p>
          <Nav
            defaultActiveKey="tab1"
            className='nav-tabs'
            onSelect={(selectedKey) => setActiveTab(selectedKey)}
          >
            <Nav.Item>
              <Nav.Link eventKey="tab1">{t('homePage.main.tab1')}</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="tab2">{t('homePage.main.tab2')}</Nav.Link>
            </Nav.Item>
          </Nav>
          <div className='home-page__main-link'>
            <CustomLink
              to={activeTab === 'tab1' ? '/services/servicenow' : '/services/data-analytics'}
              title={t('homePage.main.link')}/>
          </div>
        </div>
        </div>
      </section>

      <section className='home-page__about'>
        <div className="section-wrapper">
          <h2>{t('homePage.about.title')}</h2>
          <div className='home-page__about-block'>
            <div className='home-page__about-info'>
              <p dangerouslySetInnerHTML={
                {__html: t('homePage.about.descriptionFirstPart', {interpolation: {escapeValue: false}})}
              }/>
              <p dangerouslySetInnerHTML={
                {__html: t('homePage.about.descriptionSecondPart', {interpolation: {escapeValue: false}})}
              }/>
              <p dangerouslySetInnerHTML={
                {__html: t('homePage.about.descriptionThirdPart', {interpolation: {escapeValue: false}})}
              }/>
              <p dangerouslySetInnerHTML={
                {__html: t('homePage.about.descriptionForthPart', {interpolation: {escapeValue: false}})}
              }/>
              <br />
              <CustomLink to={'/about'} title={t('homePage.about.link')}/>
            </div>
            <div className='home-page__about-statistics'>
              {messages.about.statistics.options?.map((el, index) => (
                <div key={index}>
                  <p>{el.number}</p>
                  <p>{el.description}</p>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>

      <section className='home-page__services'>
        <div className="section-wrapper">
          <h2>What We Do</h2>
          <div className='home-page__services-block'>
            {messages.services.options?.map((el, index) => (
              <div className='home-page__services-item' key={index}>
                <div className='home-page__services-item-img'>
                  <img src={servicesParams[index]?.imgHref} alt="icon"/>
                </div>
                <h3>{el.title}</h3>
                <p>{el.description}</p>
                <div className='home-page__services-item-link'>
                  <CustomLink to={servicesParams[index]?.link} title={el.title}/>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/*<section className='home-page__projects'>
        <div className="section-wrapper">
          <h2>{t('homePage.projects.title')}</h2>
          <Projects projectsList={getProjectsList()} size="large"/>
        </div>
      </section>*/}

      <section className='home-page__form'>
        <div className="section-wrapper">
          <div className='home-page__form-block'>
            <h2>{t('homePage.form.title')}</h2>
            <p>
              {t('homePage.form.description')}
              <svg xmlns="http://www.w3.org/2000/svg" width="32" height="33" viewBox="0 0 32 33" fill="none">
                <path
                  d="M18.7 24.9276L17.2667 23.5276L22.8667 17.9276H5.33334V15.9276H22.8667L17.2333 10.2943L18.6667 8.89429L26.7 16.9276L18.7 24.9276Z"
                  fill="#DDDDDD"/>
              </svg>
            </p>
          </div>
          <ContactForm theme='dark' pageType='contact-us'/>
        </div>
      </section>

      {/*<CookiesModal isShow={isShow} setIsShow={setIsShow}/>*/}
    </div>
  )
}

export {HomePage}
