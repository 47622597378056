import {useContext, useEffect, useState, useCallback} from "react";
import {useTranslation} from "react-i18next";

import {Context} from "../../common/scripts/context";
import {useWindowSize} from "../../common/hooks/useWindowSize";
import {activateScrollPage} from "../../common/scripts/activateScrollPage";
import {PageNav} from "../../components/PageNav";
import {InfoBlock} from "../../components/InfoBlock";
import {CustomCarousel} from "../../components/CustomCarousel";
import {Timer} from "../../components/Timer";
import {Breadcrumbs} from "../../components/Breadcrumbs";

import monitorDashboardLightImage from "../../common/images/monitor-dashboard-light.svg";
import sitemapOutlineLightImage from "../../common/images/sitemap-outline-light.svg";
import axisArrowLightImage from "../../common/images/axis-arrow-light.svg";
import customerPhotoImage from "../../common/images/customer-photo.png";
import {CustomCalendar} from "../../components/CustomCalendar";
import {CustomAccordion} from "../../components/CustomAccordion";

const ServiceNowTrainingPage = () => {
  const {t, i18n} = useTranslation();
  const defaultMessage = t('servicesNowTrainingPage', {returnObjects: true});
  const defaultReviewsMessage = t('trainingReviewsCarousel', {returnObjects: true});

  const {setFooterTheme} = useContext(Context);
  const [activeLink, setActiveLink] = useState();
  const [messages, setMessages] = useState(defaultMessage);
  const [reviewsMessages, setReviewsMessages] = useState(defaultReviewsMessage);
  const {height} = useWindowSize();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    setFooterTheme('light align-left');
  }, [setFooterTheme]);

  useEffect(() => {
    activateScrollPage(height, activeLink, setActiveLink);
  }, [activeLink, setActiveLink, height]);

  useEffect(() => {
    setMessages(defaultMessage);
    setReviewsMessages(defaultReviewsMessage)
  }, [i18n.language, t]);

  const trainingDates = [
    '2023/08/01',
    '2023/08/08',
    '2023/08/15',
    '2023/08/22',
    '2023/09/01',
    '2023/09/08',
    '2023/09/15',
    '2023/09/22',
    '2023/10/05',
    '2023/10/12',
    '2023/10/19',
  ]; // YYYY/MM/DD

  const minDate = new Date("2023/08/01"); // YYYY/MM/DD must be the same as in line 86
  const maxDate = new Date("2023/10/31"); // YYYY/MM/DD must be the same as in line 93

  const verticalMenuLinkArray = [
    {href: "#main", title: messages.menuLinks[0]},
    {href: "#about", title: messages.menuLinks[1]},
    {href: "#people", title: messages.menuLinks[2]},
    {href: "#reasons", title: messages.menuLinks[3]},
    // {href: "#place", title: messages.menuLinks[4]},
    {href: "#program", title: messages.menuLinks[5]},
    {href: "#reviews", title: messages.menuLinks[6]},
    {href: "#prices", title: messages.menuLinks[7]},
  ];

  const aboutParams = [
    {imgHref: monitorDashboardLightImage},
    {imgHref: sitemapOutlineLightImage},
    {imgHref: axisArrowLightImage},
  ];

  const reviewsPhoto = [
    {imgHref: customerPhotoImage},
    {imgHref: customerPhotoImage}
  ];

  const getReviewsList = useCallback(() => {
      const array = []
      reviewsMessages.map((el, index) => (
        array.push({
          name: el.name,
          info: el.info,
          description: el.description,
          imgHref: reviewsPhoto[index]?.imgHref
        })
      ));
      return array;
    }, [reviewsMessages],
  );

  return (
    <div className='services-now-training-page page'>
      <div className='services-now-training-page__blocks'>
        <div className='services-now-training-page__info-block'>
          <section className='services-now-training-page__main' id="main">
            <Breadcrumbs/>

            <h2>{t('servicesNowTrainingPage.main.title')}</h2>

            <div className="services-now-training-page__main-timer">
              <Timer/>
            </div>

            <p>{t('servicesNowTrainingPage.main.description')}</p>
            <div className='services-now-training-page__main-video video-container'>
              <iframe
                className="video-content"
                width="100%"
                height="100%"
                src="https://www.youtube.com/embed/9rmjsDbMqbM"
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              />
            </div>
          </section>

          <section className='services-now-training-page__about' id="about">
            <h3>{t('servicesNowTrainingPage.about.title')}</h3>
            <p>{t('servicesNowTrainingPage.about.description')}</p>

            <div className='services-now-training-page__about-block'>
              {messages.about.options.map((el, index) => (
                <InfoBlock
                  key={index}
                  title={el.title}
                  info={el.info}
                  imgHref={aboutParams[index].imgHref}
                  theme='dark'
                />
              ))}
            </div>
          </section>

          <section className='services-now-training-page__people' id="people">
            <h3>{t('servicesNowTrainingPage.people.title')}</h3>
            <p>{t('servicesNowTrainingPage.people.description')}</p>

            <div className='services-now-training-page__people-video video-container'>
              <iframe
                className="video-content"
                width="100%"
                height="100%"
                src="https://www.youtube.com/embed/KXKdQUSTqyc"
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              />
            </div>
          </section>

          <section className='services-now-training-page__reasons' id="reasons">
            <h3>{t('servicesNowTrainingPage.reasons.title')}</h3>
            <p>{t('servicesNowTrainingPage.reasons.description')}</p>

            <div className='services-now-training-page__reasons-block list-block'>
              {messages.reasons.options.map((el, index) => (
                <div className="list-block__item" key={index}>
                  <h3>{el.title}</h3>
                  <p>{el.description}</p>
                </div>
              ))}
            </div>

            <p>{t('servicesNowTrainingPage.reasons.description2')}</p>
          </section>


          <section className='services-now-training-page__program' id="program">
            <h3>{t('servicesNowTrainingPage.program.title')}</h3>
            <p>{t('servicesNowTrainingPage.program.description')}</p>

            <CustomAccordion messages={messages.program}/>

            <h4>{t('servicesNowTrainingPage.program.calendar-title')}</h4>
            <CustomCalendar minDate={minDate} maxDate={maxDate} trainingDates={trainingDates} />
          </section>

          <section className='services-now-training-page__reviews' id="reviews">
            <CustomCarousel
              carouselList={getReviewsList()}
              title={t('servicesNowTrainingPage.reviews.title')}
            />
          </section>

          <section className='services-now-training-page__prices' id="prices">
            <h3>{t('servicesNowTrainingPage.prices.title')}</h3>

            <div className="services-now-training-page__prices-block">
              {messages.prices.options.map((el, index) => (
                <div key={index}>
                  <h3>{el.title}</h3>
                  <p><span>{el.price}</span>/{t('servicesNowTrainingPage.prices.person')}</p>
                  {el.number && (
                    <p>{`${el.number} ` + t('servicesNowTrainingPage.prices.students')}</p>
                  )}
                </div>
              ))}
            </div>
          </section>
        </div>

        <div className='services-now-training-page__menu-block'>
          <PageNav
            linkArray={verticalMenuLinkArray}
            activeLink={activeLink}
            linkTitle={t('servicesNowTrainingPage.menuButton')}
            link="/services/servicenow-training/register-for-training"
          />
        </div>
      </div>
    </div>
  )
}

export {ServiceNowTrainingPage}
