export const getDefaultLanguage = () => {
  const windowLanguage = window.navigator.language.slice(0, 2);

  if (windowLanguage === 'ru') {
    return 'ru';
  }
  if (windowLanguage === 'de') {
    return 'de';
  }
  return 'en';
};
