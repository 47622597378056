import {NavLink} from "react-router-dom";
import {useContext, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";

import {Context} from "../common/scripts/context";
import {CustomLink} from "../components/CustomLink";

import firstCareersImage from "../common/images/job_main.jpg";
import secondCareersImage from "../common/images/Student.jpg";
import thirdCareersImage from "../common/images/Entity.jpg";
import forthCareersImage from "../common/images/Expert.jpg";
import fifthCareersImage from "../common/images/young.jpg";

const CareersPage = () => {
  const {t, i18n} = useTranslation();
  const defaultMessage = t('careersPage', {returnObjects: true});

  const {setFooterTheme} = useContext(Context);
  const [messages, setMessages] = useState(defaultMessage);

  const opportunitiesParams = [
    {imgHref: secondCareersImage, link: "/careers/opportunity/opportunity-1"},
    {imgHref: thirdCareersImage, link: "/careers/opportunity/opportunity-2"},
    {imgHref: forthCareersImage, link: "/careers/opportunity/opportunity-3"},
    {imgHref: fifthCareersImage, link: "/careers/opportunity/opportunity-4"},
  ];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    setFooterTheme('dark');
  }, [setFooterTheme]);

  useEffect(() => {
    setMessages(defaultMessage);
  }, [i18n.language, t]);

  return (
    <div className='careers-page page'>
      <section className='careers-page__main'>
        <div className="section-wrapper">
          <h2>{t('careersPage.main.title')}</h2>
          <div className='careers-page__main-block'>
            <img src={firstCareersImage} alt="Career Opportunities"/>
            <h4>{t('careersPage.main.subtitle')}</h4>
            <p>{t('careersPage.main.description')}</p>
          </div>
        </div>
      </section>

      <section className='careers-page__opportunities'>
        <div className="section-wrapper">
          <h2>{t('careersPage.opportunity.title')}</h2>
          <div className='careers-page__opportunities-blocks'>
            {messages.opportunity?.options.map((el, index) => (
              <div className='careers-opportunity-block' key={index}>
                <img src={opportunitiesParams[index].imgHref} alt="Career Opportunities"/>
                <div>
                  <h4>{el.title}</h4>
                  <p>{el.subtitle}</p>
                  <p>{el.description}</p>
                  <div className="careers-opportunity-block__link">
                    {/*<CustomLink to={opportunitiesParams[index].link}/>*/}
                    <CustomLink to="/careers/contact-us" title={messages.opportunity.contactLink}/>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>

      <section className='careers-page__connection'>
        <div className="section-wrapper">
          <h3>{t('careersPage.connection.title')}</h3>
          <p>{t('careersPage.connection.description')}</p>
          <div className='careers-page__connection-button'>
            <NavLink className='nav-button' to='/contact-us'>{t('careersPage.connection.button')}</NavLink>
          </div>
        </div>
      </section>
    </div>
  )
}

export {CareersPage}
