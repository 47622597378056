import {useCallback, useContext, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";

import {Context} from "../../common/scripts/context";
import {useWindowSize} from "../../common/hooks/useWindowSize";
import {activateScrollPage} from "../../common/scripts/activateScrollPage";
import {PageNav} from "../../components/PageNav";
import {Paragraphs} from "../../components/Paragraphs";
import {CustomLink} from "../../components/CustomLink";
import {CustomCarousel} from "../../components/CustomCarousel";
import {Projects} from "../../components/Projects";
import {Breadcrumbs} from "../../components/Breadcrumbs";

import customerPhotoImage from "../../common/images/customer-photo.png";
import platformProjectImage from "../../common/images/platform-project.png";
import cagesProjectImage from "../../common/images/cages-projest.png";
import dashboardProjectImage from "../../common/images/dashboard-projest.png";

const ServiceNowPage = () => {
  const {t, i18n} = useTranslation();
  const defaultMessage = t('serviceNowPage', {returnObjects: true});
  const defaultReviewsMessage = t('serviceNowReviewsCarousel', {returnObjects: true});

  const {setFooterTheme} = useContext(Context);
  const [activeLink, setActiveLink] = useState();
  const [messages, setMessages] = useState(defaultMessage);
  const [reviewsMessages, setReviewsMessages] = useState(defaultReviewsMessage);
  const {height} = useWindowSize();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    setFooterTheme('light align-left');
  }, [setFooterTheme]);

  useEffect(() => {
    activateScrollPage(height, activeLink, setActiveLink);
  }, [activeLink, setActiveLink, height])

  useEffect(() => {
    setMessages(defaultMessage);
    setReviewsMessages(defaultReviewsMessage)
  }, [i18n.language, t]);

  const verticalMenuLinkArray = [
    {href: "#main", title: messages.menuLinks[0]},
    {href: "#products", title: messages.menuLinks[1]},
    /*{href: "#projects", title: messages.menuLinks[2]},*/
    {href: "#about", title: messages.menuLinks[3]},
    {href: "#reviews", title: messages.menuLinks[4]},
    {href: "#training", title: messages.menuLinks[5]},
  ];

  const reviewsPhoto = [
    {imgHref: customerPhotoImage},
    {imgHref: customerPhotoImage},
    {imgHref: customerPhotoImage},
    {imgHref: customerPhotoImage}
  ];

  const getReviewsList = useCallback(() => {
    return reviewsMessages.map((el, index) => ({
        name: el.name,
        info: el.info,
        description: el.description,
        imgHref: reviewsPhoto[index]?.imgHref
      }));
    }, [reviewsMessages],
  );

  const projectsPhoto = [
    {imgHref: platformProjectImage},
    {imgHref: cagesProjectImage},
    {imgHref: dashboardProjectImage},
  ]

  const getProjectsList = useCallback(() => {
      return messages.projects.projectsList.map((el, index) => ({
        blockSize: 'small',
        imgHref: projectsPhoto[index].imgHref,
        title: el.title,
        subtitle: el.subtitle,
        description: el.description,
        link: `/services/servicenow/projects/${el.linkTitle}`
      }));
    }, [],
  );

  return (
    <div className='service-now-page page'>
      <div className='service-now-page__blocks'>
        <div className='service-now-page__info-block'>
          <section className='service-now-page__main' id="main">
            <Breadcrumbs/>

            <h2>{t('serviceNowPage.main.title')}</h2>
            <p>{t('serviceNowPage.main.description')}</p>

            <div className='service-now-page__main-video video-container'>
              <iframe
                className="video-content"
                width="100%"
                height="100%"
                src="https://www.youtube.com/embed/yDmGTeEDp5g"
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              />
            </div>
          </section>

          <section className='service-now-page__products' id="products">
            <h3>{t('serviceNowPage.products.title')}</h3>
            <p>{t('serviceNowPage.products.description')}</p>
            <div className='service-now-page__products-chips chips-block'>
              {messages.products.experts.map((el, index) => (
                <div className='green' key={index}>{el}</div>
              ))}
            </div>
          </section>

          {/* <section className='service-now-page__projects' id="projects">
            <h3>{t('serviceNowPage.projects.title')}</h3>
            <Projects projectsList={getProjectsList()} size="small"/>
          </section> */}

          <section className='service-now-page__about' id="about">
            <Paragraphs paragraphsList={messages.about} theme="dark"/>
          </section>

          <section className='service-now-page__reviews' id="reviews">
            <CustomCarousel
              carouselList={getReviewsList()}
              title={t('serviceNowPage.reviews.title')}
            />
          </section>

          <section className='service-now-page__training' id="training">
            <h3>{t('serviceNowPage.training.title')}</h3>
            <p>{t('serviceNowPage.training.description')}</p>
            <CustomLink to="/services/servicenow-training" title={t('serviceNowPage.training.link')}/>
          </section>
        </div>

        <div className='service-now-page__menu-block'>
          <PageNav
            linkArray={verticalMenuLinkArray}
            activeLink={activeLink}
            link="/services/servicenow/contact-us"
          />
        </div>
      </div>
    </div>
  )
}

export {ServiceNowPage}
