import {NavLink} from "react-router-dom";
import {useTranslation} from "react-i18next";

const PageNav = ({linkArray, activeLink, linkTitle, link}) => {
  const {t} = useTranslation();

  return (
    <div className='page-nav'>
      <ul className='page-nav__links'>
        {linkArray.map((el, index) => {
          const activeClass = activeLink === el.href.replace('#', '') ? ' active' : '';

          return (
            <li className='page-nav__links-item' key={index}>
              <a className={`page-nav__link${activeClass}`} href={el.href}>{el.title}</a>
            </li>
          )
        })}
      </ul>

      <div className='page-nav__button'>
        <NavLink className='nav-button' to={link ?? '/contacts'}>
          {linkTitle ?? t('contactLink')}
        </NavLink>
      </div>
    </div>
  )
}

export {PageNav}
