import {Route, Routes} from "react-router-dom";
import {useState} from "react";

import {Context} from "./common/scripts/context";

import {Layout} from "./components/Layout";
import {HomePage} from "./pages/HomePage";
import {AboutPage} from "./pages/AboutPage";
import {ServicesPage} from "./pages/ServicesPage";
import {ContactsPage} from "./pages/ContactsPage";
import {CareersPage} from "./pages/CareersPage";
import {ServiceNowPage} from "./pages/services/ServiceNowPage";
import {ServiceNowTrainingPage} from "./pages/services/ServiceNowTrainingPage";
import {BusinessIntelligencePage} from "./pages/services/BusinessIntelligencePage";
import {ServiceNowProjectPage} from "./pages/services/ServiceNowProjectPage";
import {BusinessIntelligenceProjectPage} from "./pages/services/BusinessIntelligenceProjectPage";
import {TermsOfUsePage} from "./pages/TermsOfUsePage";
import {PrivacyPolicyPage} from "./pages/PrivacyPolicyPage";
import {PageWithForm} from "./pages/PageWithForm";
import {ConfirmationPage} from "./pages/ConfirmationPage";
import {CareersOpportunityPage} from "./pages/CareersOpportunityPage";

const App = () => {
  const [footerTheme, setFooterTheme] = useState('dark');

  return (
    <Context.Provider
      value={{
        footerTheme,
        setFooterTheme
      }}
    >
      <Routes>
        <Route path='/' element={<Layout/>}>
          <Route index element={<HomePage/>}/>
          <Route path='services/' element={<ServicesPage/>}/>
          <Route path='services/servicenow' element={<ServiceNowPage/>}/>
          <Route
            path='services/servicenow/projects/:linkTitle'
            element={<ServiceNowProjectPage/>}
          />
          <Route
            path='services/servicenow/contact-us'
            element={<PageWithForm pageType="contact-us" formType="service-now"/>}
          />
          <Route
            path='services/servicenow/contact-us/confirmation'
            element={<ConfirmationPage pageType="services"/>}
          />
          <Route
            path='services/data-analytics'
            element={<BusinessIntelligencePage/>}
          />
          <Route
            path='services/data-analytics/projects/:linkTitle'
            element={<BusinessIntelligenceProjectPage/>}
          />
          <Route
            path='services/data-analytics/contact-us'
            element={<PageWithForm pageType="contact-us" formType="business-intelligence"/>}
          />
          <Route
            path='services/data-analytics/contact-us/confirmation'
            element={<ConfirmationPage pageType="services"/>}
          />
          <Route
            path='services/data-analytics/demo-request'
            element={<PageWithForm pageType="demo-request"/>}
          />
          <Route
            path='services/data-analytics/demo-request/confirmation'
            element={<ConfirmationPage pageType="demo-request"/>}
          />
          <Route
            path='services/servicenow-training'
            element={<ServiceNowTrainingPage/>}
          />
          <Route
            path='services/servicenow-training/register-for-training'
            element={<PageWithForm pageType="register-for-training"/>}
          />
          <Route
            path='services/servicenow-training/register-for-training/confirmation'
            element={<ConfirmationPage pageType="register-for-training"/>}
          />
          <Route path='about' element={<AboutPage/>}/>
          <Route path='contacts' element={<ContactsPage/>}/>
          <Route path='careers' element={<CareersPage/>}/>
          <Route
            path='careers/contact-us'
            element={<PageWithForm pageType="apply-now"/>}
          />
          <Route path='careers/contact-us/confirmation' element={<ConfirmationPage pageType="services"/>}/>
          <Route path='careers/opportunity/:linkTitle' element={<CareersOpportunityPage/>}/>
          <Route path='impressum' element={<TermsOfUsePage/>}/>
          <Route path='policy' element={<PrivacyPolicyPage/>}/>
          <Route path='contact-us' element={<PageWithForm pageType="contact-us"/>}/>
          <Route path='contact-us/confirmation' element={<ConfirmationPage pageType="services"/>}/>
        </Route>
      </Routes>
    </Context.Provider>
  );
}

export default App;
