import {Carousel} from "react-responsive-carousel";
import {useState} from "react";

const CustomCarousel = ({carouselList, title}) => {
  const [currentSlide, setCurrentSlide] = useState(0);

  const next = () => {
    setCurrentSlide(currentSlide + 1);
  };

  const prev = () => {
    setCurrentSlide(currentSlide - 1);
  };

  const updateCurrentSlide = (index) => {
    if (currentSlide !== index) {
      setCurrentSlide(index);
    }
  };

  return (
    <>
      <div className="carousel-header">
        <h3>{title}</h3>
        <div className="carousel-header__buttons">
          <button className="carousel-header__buttons-prev" onClick={prev}/>
          <button className="carousel-header__buttons-next" onClick={next}/>
        </div>
      </div>
      <Carousel
        infiniteLoop
        showIndicators={false}
        showArrows={false}
        showStatus={false}
        showThumbs={false}
        selectedItem={currentSlide}
        onChange={updateCurrentSlide}
      >
        {carouselList?.map((el, index) => (
          <div className="carousel-item" key={index}>
            {/* Enable / Disable Photo
            <div className="carousel-item__img">
              <img src={el.imgHref} alt="User photo"/>
            </div> */}
            <div className="carousel-item__info">
              <div>
                <h3 className="carousel-item__info-title">{el.name}</h3>
                <p className="carousel-item__info-text">{el.info}</p>
                <p className="carousel-item__info-description">{el.description}</p>
              </div>
              <p className="carousel-item__info-page">{index + 1} / {carouselList.length}</p>
            </div>
          </div>
        ))}
      </Carousel>
    </>
  )
}

export {CustomCarousel}
