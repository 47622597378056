import {NavLink} from "react-router-dom";
import {CustomParagraph} from "./CustomParagraph";

const Paragraphs = ({paragraphsList, theme}) => {
  return (
    <div className={`paragraphs ${theme ?? ''}`}>
      <h3>{paragraphsList?.title}</h3>
      <p>{paragraphsList?.description}</p>

      <div className="paragraphs__paragraphs">
        {paragraphsList?.options.map((el, index) => (
          <CustomParagraph title={el} key={index}/>
        ))}
      </div>

      {paragraphsList?.button && (
        <div className='paragraphs__button'>
          <NavLink className='nav-button' to='/contacts'>
            {paragraphsList?.button}
          </NavLink>
        </div>
      )}
    </div>
  )
}

export {Paragraphs}
