import {useTimer} from "react-timer-hook";
import {useTranslation} from "react-i18next";
import {buildStyles, CircularProgressbar} from "react-circular-progressbar";

const Timer = () => {
  const {t} = useTranslation();
  // Set past date to make everithing 0000000
  const {days, hours, minutes, seconds} = useTimer({expiryTimestamp: new Date("2022/10/22")}); // YYYY/MM/DD

  const formatTime = (time) => {
    return String(time).padStart(2, '0')
  };

  return (
    <div className="timer">
      <div className="timer-block">
        <p>{t('timerBlock.info1')}</p>
        <div className="timer-block__item">
          <span>{formatTime(days)}</span>
          <p>{t('timerBlock.param1')}</p>
        </div>
        <span className="timer-block__line"/>
        <div className="timer-block__item">
          <span>{formatTime(hours)}</span>
          <p>{t('timerBlock.param2')}</p>
        </div>
        <span className="timer-block__line"/>
        <div className="timer-block__item">
          <span>{formatTime(minutes)}</span>
          <p>{t('timerBlock.param3')}</p>
        </div>
        <span className="timer-block__line"/>
        <div className="timer-block__item">
          <span>{formatTime(seconds)}</span>
          <p>{t('timerBlock.param4')}</p>
        </div>
      </div>

      {/* <div className="progressbar-block">
        <CircularProgressbar
          value='56'
          strokeWidth='11'
          styles={buildStyles({
            strokeLinecap: 'butt',
          })}
        />
        <div className="progressbar-block__percent">
          <span>56%</span>
          <p>{t('timerBlock.info2')}</p>
        </div>
      </div> */}

      <div className="no-dates-block">
        <p>{t('timerBlock.noDates')}</p>
      </div>
    </div>
  )
}

export {Timer}
